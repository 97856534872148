import PropTypes from "prop-types"
import React from "react"

import Header from "./common/Header"
import Footer from "./common/Footer"
import Hero from "./index/Hero"

function Layout({ children }) {
  return (
    <div className="gradient text-gray-900">
      <div className="flex flex-col h-[50vh] md:h-screen heading">
        <div>
          <Header />
        </div>

        <div className="flex-grow h-full flex overflow-hidden">
          <Hero />
        </div>
      </div>
      <div className="">
        <main className="flex-1 w-full mx-auto">{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
