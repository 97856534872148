import React from "react"

import HeroSlider from "./HeroSlider"

const Hero = () => {
  return (
    <div className="h-full flex-1 flex w-full flex-col ">
      <div className="w-full h-full flex-grow relative ">
        <HeroSlider />
      </div>
    </div>
  )
}

export default Hero
