import React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Valkommen() {
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 36 }) {
        id
        title
        content
      }
    }
  `)
  const page = data.wpPage
  return (
    <div className="py-12">
      <section className="flex flex-col flex-wrap max-w-3xl mx-auto text-center px-8 ">
        <header className="text-cherry acme uppercase text-2xl md:text-3xl">
          <div className="text-xl">Välkommen till</div>
          <h1 className="">
            <div dangerouslySetInnerHTML={{ __html: page.title }}></div>
          </h1>
        </header>
        <div className="mt-4 text-xl md:text-2xl text-gray-800 leading-relaxed">
          <div dangerouslySetInnerHTML={{ __html: page.content }}></div>
        </div>
      </section>
    </div>
  )
}

export default Valkommen
