import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export default function HeroSlider() {
  const data = useStaticQuery(graphql`
    {
      allWpBild(filter: { databaseId: { eq: 367 } }) {
        nodes {
          id
          title
          bildspel {
            bilder {
              bild {
                localFile {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const slides = data.allWpBild.nodes[0].bildspel.bilder
  console.log(slides)

  const settings = {
    dots: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }
  return (
    <div className="h-full w-full ">
      <Slider {...settings} className="h-full w-full">
        {slides.map((slide, i) => {
          return <Slide key={i} slide={slide} />
        })}
      </Slider>
    </div>
  )
}

function Slide({ slide }) {
  const img = slide.bild.localFile.childImageSharp
  console.log(img)
  return (
    <div className="h-full w-full  ">
      <GatsbyImage
        image={getImage(img)}
        className="h-full w-full object-cover relative "
      />
    </div>
  )
}
