import React from "react"
import { useStaticQuery, graphql } from "gatsby"
function Pask() {
  const data = useStaticQuery(graphql`
    {
      wpPage(slug: {}, databaseId: { eq: 384 }) {
        id
        content
      }
    }
  `)
  const { content } = data.wpPage
  return (
    <div className="w-full ">
      <fieldset className="border-cherry border-2 px-2 rounded-md text-center mx-auto menu-box">
        <legend>
          <h2 className="text-2xl uppercase acme text-cherry relative inline-block -mt-6 px-8 bg-transparent">
            Clemens Påsk
          </h2>
        </legend>

        <div className="my-6 px-2">
          {" "}
          <div
            className="wp-content pask"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </fieldset>
    </div>
  )
}

export default Pask
